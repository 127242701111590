import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Tokens } from '@mint-lib/api';
import { AuthContext } from '@mint-lib/auth';
import { TFunction, useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import {
  fetchOriginUserProfile,
  useAgencyWorkspaceId,
  useIsAgencyCompany,
  usePermissions,
  useProfile,
} from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';
import { useNavigate } from '@mint/routing';
import { useDebouncedCallback } from 'use-debounce';

import {
  Box,
  Button,
  SearchBar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@myn/mui';

import { selectWorkspace } from '../../api/workspaces/workspaces.js';
import { PERMISSIONS_MAP } from '../../constants.js';
import { useModal } from '../../context/ModalContext/useModal.js';
import useConditionalSearch from '../../hooks/useConditionalSearch/useConditionalSearch.js';
import useTab, { RouteTabs } from '../../hooks/useTab/useTab.js';

export const CreateModalLabelMap: (
  t: TFunction,
) => Record<RouteTabs, string> = (t: TFunction) => ({
  [RouteTabs.User]: t('New user'),
  [RouteTabs.Role]: t('New role'),
});

const Filters = () => {
  const { t } = useTranslation('@myn/permissions');
  const ff = useFlags('@myn/guest', ['ssoLogin']);

  const auth = useContext(AuthContext);

  const isAgencyRoute = useIsAgencyCompany();
  const agencyWorkspaceId = useAgencyWorkspaceId();
  const { profile, setProfileData } = useProfile();

  const [tokens, setTokens] = useState<Tokens | null>(null);

  const { setOpen: setIsNewModalOpened } = useModal();

  const navigate = useNavigate();

  const { search } = useConditionalSearch();

  const ref = useRef<HTMLInputElement | null>(null);

  const activeRoute = useTab();

  const { getPermissionField } = usePermissions();

  const isAgencyCompany = useIsAgencyCompany();

  const isReadonlyPermission = useMemo(
    () =>
      getPermissionField('usersPermissionsFeature') === PERMISSIONS_MAP.View,
    [getPermissionField('usersPermissionsFeature')],
  );

  const label = CreateModalLabelMap(t)[activeRoute ?? RouteTabs.User];

  useEffect(() => {
    if (tokens) {
      auth.saveTokens(tokens);

      // update profile data to handle checkmark for active WS
      fetchOriginUserProfile()
        .then((res) => {
          setProfileData(res);
        })
        .then(() => {
          navigate({
            to: '/agency-hub/users-management/user',
          });
        })
        .catch(() => {
          enqueueSnackbar({
            variant: 'error',
            title: t('Error'),
            subTitle: t('Something went wrong. Please try again'),
            removable: true,
          });
        });
    }
  }, [tokens]);

  const handleChange = useCallback(
    (searchQuery?: string) => {
      navigate({
        search: () => ({ search: searchQuery }),
      });
    },
    [navigate],
  );

  const handleRedirectToAgency = useCallback(() => {
    const implyUrl =
      profile &&
      // eslint-disable-next-line
      `${new URL(profile?.profile?.company?.dashboardUrl)?.origin}/logout`;

    if (implyUrl) {
      localStorage.setItem('implyLogout', implyUrl);
    }

    selectWorkspace(setTokens, agencyWorkspaceId);
  }, [navigate]);

  const onChangeDebounced = useDebouncedCallback(handleChange, 300);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
        mr: '2px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: '2px',
        }}
      >
        <Typography variant="h3">{t('Users Management')}</Typography>
        {isAgencyCompany && activeRoute === 'role' ? null : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: (theme) => theme.spacing(2),
              mr: '2px',
            }}
          >
            <SearchBar
              innerRef={ref}
              size="medium"
              defaultValue={search}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onChangeDebounced(e.target.value || undefined);
              }}
              onClear={() => {
                handleChange(undefined);

                if (ref.current) {
                  ref.current.value = '';
                }
              }}
              placeholder={`${t('Search')}`}
              sx={{ width: '300px' }}
            />
            {!!agencyWorkspaceId &&
            ff.ssoLogin.enabled &&
            activeRoute === 'user' ? (
              <Tooltip
                title={t(
                  'To add a new user you’ll be redirected to your agency workspace.',
                )}
                placement="bottom-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => handleRedirectToAgency()}
                  startIcon={'Launch'}
                >
                  {label}
                </Button>
              </Tooltip>
            ) : isReadonlyPermission ? null : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => setIsNewModalOpened(true)}
                startIcon={'Add'}
              >
                {label}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Tabs
        value={activeRoute}
        indicatorPosition="bottom"
        orientation="horizontal"
      >
        <Tab
          label={t('Users')}
          value={'user'}
          onClick={() => {
            navigate({
              to: isAgencyRoute
                ? '/agency-hub/users-management/user'
                : '/company-profile/users-management/user',
            });
          }}
        />
        <Tab
          label={t('Roles')}
          value={'role'}
          onClick={() => {
            navigate({
              to: isAgencyCompany
                ? '/agency-hub/users-management/role'
                : '/company-profile/users-management/role',
            });
          }}
        />
      </Tabs>
    </Box>
  );
};

export default Filters;
